@charset "utf-8";

* {box-sizing: border-box;outline: medium none; text-decoration:none;}
img {max-width:100%; border: 0;vertical-align: middle;}
a{text-decoration: none;border: 0;outline: none; cursor: pointer;}
a:hover, a:active, a:visited, a:focus {text-decoration: none; outline: 0 none;}
ul, ol, li{ list-style:none; margin:0; padding:0; background:none;}
body{margin:0 auto; padding:0;-webkit-overflow-scrolling: touch; touch-action: manipulation;background:#fff;-moz-osx-font-smoothing:grayscale;-webkit-font-smoothing:antialiased}
hr{ border: none;}
body, input, textarea, select, button {text-rendering: optimizeSpeed;font-family: 'Poppins', sans-serif;
 -webkit-tap-highlight-color:transparent;}
input[type=number]{-moz-appearance: textfield;}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button{-webkit-appearance: none;margin: 0;}
select::-ms-expand {display: none;}
select {-webkit-appearance: none; -moz-appearance: none; appearance: none; } 
p{margin: 0 0 10px;}
h1, h2, h3, h4, h5, h6{font-size:100%;margin:0;padding:0}
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {display: block;}
input::-ms-clear {width : 0;height: 0;}


.mainwrapper{width:100%;position:relative;display:inline-block;}
.innercontainer{max-width:1140px;margin:0 auto;width:100%;}


.finnovateicon{display: inline-block;background-repeat:no-repeat;vertical-align:middle;background-size:contain;background-position:center;flex:none;}
.rupicon{font-family: system-ui; font-weight: normal; flex: none; width: auto;}
.divider{height:6px;background:#f2f2f2;border-bottom:solid 0.5px #eee;}

.text_uppercase{text-transform:uppercase}
.dn{display:none!important}
.inline_block{display:inline-block}
.dblock {display: block;}
.display_none{display:none}
.text_left{text-align:left}
.text_right{text-align:right}
.text_center{text-align:center}
.anchor:hover{text-decoration:underline;cursor: pointer;color:#0076D7;transition: all 0.3s;}
.pointer{cursor: pointer;}
.flex_center{display: flex;align-items: center;}
.justify_center{justify-content: space-between;}


.bluebtn{display: inline-flex; justify-content: center; align-items: center; flex-flow: row nowrap; height: 32px; min-width: 65px; padding: 0 12px; color: #fff; border: 0; border-radius: 8px; background-color: #0D6CCA; outline: none;}
.bluebtn[disabled]{background-color: #CFD8E2; pointer-events: none;}



.bluefill_animate{background-color:#0076D7;border:1px solid #0076D7;transition-property: background-color, background-image, broder-color, box-shadow;transition: all 0.8s;}
.grayfill_animate{background-color:#f5f2f2;border:1px solid #f5f2f2;transition-property: background-color, background-image, broder-color, box-shadow;transition: all 0.8s;}
.blackfill_animate{background-color:#111111;border:1px solid #111111;transition-property: background-color, background-image, broder-color, box-shadow;transition: all 0.8s;}
.ornagefill_animate{background-color:#fc6e01;border:1px solid #111111;transition-property: background-color, background-image, broder-color, box-shadow;transition: all 0.8s;}
.whitefill_animate{background-color:#FFFFFF;border:none;transition-property: background-color, background-image, broder-color, box-shadow;transition: all 0.8s;}
.gray_whitefill_animate{background-color:#FFFFFF;border:1px solid #EEEEEE;transition-property: background-color, background-image, broder-color, box-shadow;transition: all 0.8s;}
.blue_whitefill_animate{background-color:#FFFFFF;border:1px solid #0076D7;transition-property: background-color, background-image, broder-color, box-shadow;transition: all 0.8s;}

.bluefill_animate:hover{border-color:#41A9FF;background-color: #41A9FF;box-shadow: 0 1px 4px 0 rgb(0 0 0 / 30%);}
.blackfill_animate:hover{border-color:#000000;background-color: #363636;box-shadow: 0 1px 4px 0 rgb(0 0 0 / 30%);}
.ornagefill_animate:hover{border-color:#ff7d18;background-color: #ff7d18;box-shadow: 0 1px 4px 0 rgb(0 0 0 / 30%);}
.grayfill_animate:hover{border-color:#0076D7;background-color: #0076D7;color:#FFFFFF;box-shadow: 0 1px 4px 0 rgb(0 0 0 / 30%);}
.whitefill_animate:hover{background-color: #ebebeb;}
.gray_whitefill_animate:hover{border-color:#EEEEEE;background-color: #ebebeb;}
.blue_whitefill_animate:hover{border-color:#0076D7;background-color: #ebebeb;}


.line_clamp_1{display:-webkit-box;-webkit-line-clamp:1;-webkit-box-orient:vertical;overflow:hidden}
.line_clamp_2{display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;overflow:hidden}
.line_clamp_3{display:-webkit-box;-webkit-line-clamp:3;-webkit-box-orient:vertical;overflow:hidden}

.colorFFF{color:#FFFFFF;}
.color111{color:#111111;}
.color333{color:#333333;}
.color777{color:#777777;}
.color007{color:#0076D7;}
.color339{color:#339D3A;}
.colorEC4{color:#EC4F4F;}
.colorF3C{color:#F3C50B;}
.colorEEE{color:#EEEEEE;}
.colorCCC{color:#CCCCCC;}
.colorFF6{color:#FF6E00;}

/* finnovate color pallate */
.color182{color: #182338;}
.color212{color: #212121;}
.color495{color: #495262;}
.color212{color: #212121;}
.colorfa8{color: #FA8E77;}
.color6d7{color: #6D7584;}
.color384{color: #38465F;}
.color626{color: #626E84;}
.color353{color:#353F51}
.color06d{color: #0D6CCA;}
.color0d6{color: #0D6CCA;}
.color9ba{color: #9BA7BC;}
.colorf47{color: #F47B18;}
.color8c9{color: #8C9CB9;}
.color162{color: #162034;}


.goldtext{color: #C79E5B;}
.greentext{color: #09A635;}
.bluetext{color: #0D6CCA;}
.redtext{color: #EE616A;}

.fw300{font-weight:300}
.fw400{font-weight:400}
.fw500{font-weight:500}
.fw600{font-weight:600}
.fw700{font-weight:700}

.font8{font-size:8px}
.font9{font-size:9px}
.font10{font-size:10px}
.font11{font-size:11px}
.font13{font-size:13px}
.font12{font-size:12px}
.font14{font-size:14px}
.font15{font-size:15px}
.font16{font-size:16px}
.font17{font-size:17px}
.font18{font-size:18px}
.font19{font-size:19px}
.font20{font-size:20px}
.font21{font-size:21px}
.font22{font-size:22px}
.font23{font-size:23px}
.font24{font-size:24px}
.font25{font-size:25px}
.font26{font-size:26px}
.font27{font-size:27px}
.font28{font-size:28px}
.font29{font-size:29px}
.font30{font-size:30px}
.font36{font-size:36px}


.p50  {padding: 50px}
.pt50 {padding-top: 50px}
.pl50 {padding-left: 50px}
.pr50 {padding-right: 50px}
.pb50 {padding-bottom: 50px}
.m50  {margin: 50px}
.mt50 {margin-top: 50px}
.ml50 {margin-left: 50px}
.mr50 {margin-right: 50px}
.mb50 {margin-bottom: 50px}
.p45  {padding: 45px}
.pt45 {padding-top: 45px}
.pl45 {padding-left: 45px}
.pr45 {padding-right: 45px}
.pb45 {padding-bottom: 45px}
.m45  {margin: 45px}
.mt45 {margin-top: 45px}
.ml45 {margin-left: 45px}
.mr45 {margin-right: 45px}
.mb45 {margin-bottom: 45px}
.p40  {padding: 40px}
.pt40 {padding-top: 40px}
.pl40 {padding-left: 40px}
.pr40 {padding-right: 40px}
.pb40 {padding-bottom: 40px}
.m40  {margin: 40px}
.mt40 {margin-top: 40px}
.ml40 {margin-left: 40px}
.mr40 {margin-right: 40px}
.mb40 {margin-bottom: 40px}
.p35  {padding: 35px}
.pt35 {padding-top: 35px}
.pl35 {padding-left: 35px}
.pr35 {padding-right: 35px}
.pb35 {padding-bottom: 35px}
.m35  {margin: 35px}
.mt35 {margin-top: 35px}
.ml35 {margin-left: 35px}
.mr35 {margin-right: 35px}
.mb35 {margin-bottom: 35px}
.p30  {padding: 30px}
.pt30 {padding-top: 30px}
.pl30 {padding-left: 30px}
.pr30 {padding-right: 30px}
.pb30 {padding-bottom: 30px}
.m30  {margin: 30px}
.mt30 {margin-top: 30px}
.ml30 {margin-left: 30px}
.mr30 {margin-right: 30px}
.mb30 {margin-bottom: 30px}
.p25  {padding: 25px}
.pt25 {padding-top: 25px}
.pl25 {padding-left: 25px}
.pr25 {padding-right: 25px}
.pb25 {padding-bottom: 25px}
.m25  {margin: 25px}
.mt25 {margin-top: 25px}
.ml25 {margin-left: 25px}
.mr25 {margin-right: 25px}
.mb25 {margin-bottom: 25px}
.p20  {padding: 20px}
.pt20 {padding-top: 20px}
.pl20 {padding-left: 20px}
.pr20 {padding-right: 20px}
.pb20 {padding-bottom: 20px}
.m20  {margin: 20px}
.mt20 {margin-top: 20px}
.ml20 {margin-left: 20px}
.mr20 {margin-right: 20px}
.mb20 {margin-bottom: 20px}
.p18  {padding: 18px}
.pt18 {padding-top: 18px}
.pl18 {padding-left: 18px}
.pr18 {padding-right: 18px}
.pb18 {padding-bottom: 18px}
.m18  {margin: 18px}
.mt18 {margin-top: 18px}
.ml18 {margin-left: 18px}
.mr18 {margin-right: 18px}
.mb18 {margin-bottom: 18px}
.p16  {padding: 16px}
.pt16 {padding-top: 16px}
.pl16 {padding-left: 16px}
.pr16 {padding-right: 16px}
.pb16 {padding-bottom: 16px}
.m16  {margin: 16px}
.mt16 {margin-top: 16px}
.ml16 {margin-left: 16px}
.mr16 {margin-right: 16px}
.mb16 {margin-bottom: 16px}
.p15  {padding: 15px}
.pt15 {padding-top: 15px}
.pl15 {padding-left: 15px}
.pr15 {padding-right: 15px}
.pb15 {padding-bottom: 15px}
.m15  {margin: 15px}
.mt15 {margin-top: 15px}
.ml15 {margin-left: 15px}
.mr15 {margin-right: 15px}
.mb15 {margin-bottom: 15px}
.p14  {padding: 14px}
.pt14 {padding-top: 14px}
.pl14 {padding-left: 14px}
.pr14 {padding-right: 14px}
.pb14 {padding-bottom: 14px}
.m14  {margin: 14px}
.mt14 {margin-top: 14px}
.ml14 {margin-left: 14px}
.mr14 {margin-right: 14px}
.mb14 {margin-bottom: 14px}
.p12  {padding: 12px}
.pt12 {padding-top: 12px}
.pl12 {padding-left: 12px}
.pr12 {padding-right: 12px}
.pb12 {padding-bottom: 12px}
.m12  {margin: 12px}
.mt12 {margin-top: 12px}
.ml12 {margin-left: 12px}
.mr12 {margin-right: 12px}
.mb12 {margin-bottom: 12px}
.p10  {padding: 10px}
.pt10 {padding-top: 10px}
.pl10 {padding-left: 10px}
.pr10 {padding-right: 10px}
.pb10 {padding-bottom: 10px}
.m10  {margin: 10px}
.mt10 {margin-top: 10px}
.ml10 {margin-left: 10px}
.mr10 {margin-right: 10px}
.mb10 {margin-bottom: 10px}
.p8  {padding: 8px}
.pt8 {padding-top: 8px}
.pl8 {padding-left: 8px}
.pr8 {padding-right: 8px}
.pb8 {padding-bottom: 8px}
.m8  {margin: 8px}
.mt8 {margin-top: 8px}
.ml8 {margin-left: 8px}
.mr8 {margin-right: 8px}
.mb8 {margin-bottom: 8px}
.p6  {padding: 6px}
.pt6 {padding-top: 6px}
.pl6 {padding-left: 6px}
.pr6 {padding-right: 6px}
.pb6 {padding-bottom: 6px}
.m6  {margin: 6px}
.mt6 {margin-top: 6px}
.ml6 {margin-left: 6px}
.mr6 {margin-right: 6px}
.mb6 {margin-bottom: 6px}
.p5  {padding: 5px}
.pt5 {padding-top: 5px}
.pl5 {padding-left: 5px}
.pr5 {padding-right: 5px}
.pb5 {padding-bottom: 5px}
.m5  {margin: 5px}
.mt5 {margin-top: 5px}
.ml5 {margin-left: 5px}
.mr5 {margin-right: 5px}
.mb5 {margin-bottom: 5px}
.p4  {padding: 4px}
.pt4 {padding-top: 4px}
.pl4 {padding-left: 4px}
.pr4 {padding-right: 4px}
.pb4 {padding-bottom: 4px}
.m4  {margin: 4px}
.mt4 {margin-top: 4px}
.ml4 {margin-left: 4px}
.mr4 {margin-right: 4px}
.mb4 {margin-bottom: 4px}
.p2  {padding: 2px}
.pt2 {padding-top: 2px}
.pl2 {padding-left: 2px}
.pr2 {padding-right: 2px}
.pb2 {padding-bottom: 2px}
.m2  {margin: 2px}
.mt2 {margin-top: 2px}
.ml2 {margin-left: 2px}
.mr2 {margin-right: 2px}
.mb2 {margin-bottom: 2px}
.p0  {padding: 0}
.pt0 {padding-top: 0}
.pl0 {padding-left: 0}
.pr0 {padding-right: 0}
.pb0 {padding-bottom: 0}
.m0  {margin: 0}
.mt0 {margin-top: 0}
.ml0 {margin-left: 0}
.mr0 {margin-right: 0}
.mb0 {margin-bottom: 0}
.mt100{margin-top: 100px;}


/* Animation */
.animated{animation-duration:0.6s;animation-fill-mode:both;transition-timing-function: ease-in-out;}

.fadeIn{animation-name:fadeIn}
@keyframes fadeIn { from{opacity:0} to{opacity:1} }

.faster{-webkit-animation-duration:calc(1s / 2);animation-duration:calc(1s / 2)}
.fast{-webkit-animation-duration:calc(1s * 0.8);animation-duration:calc(1s * 0.8)}
.slow{-webkit-animation-duration:calc(1s * 2);animation-duration:calc(1s * 2)}
.slower{-webkit-animation-duration:calc(1s * 3);animation-duration:calc(1s * 3)}

@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}


.animate_pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  animation-duration:0.6s;animation-fill-mode:both;transition-timing-function: ease-in-out;
}



@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}


@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
/* Animation */











