@charset "utf-8";
.infoBox_wpr{display: grid; grid-template-columns: repeat(5,1fr); grid-gap: 20px;}
.infoBox_outer{display: inline-flex; background-color: #FFFFFF;
    border: 1px solid #EDEFF2; box-shadow: 0px 5px 22px rgba(154, 154, 156, 0.02), 0px 4px 11px rgba(155, 155, 155, 0.02), 0px 1px 15px rgba(44, 44, 45, 0.03); border-radius: 10px; padding: 14px 20px; min-height: 137px; flex-direction: column;}
.infoBox_title{display: flex; width: 100%; flex-wrap: wrap; color: #353F51; font-size: 13px; font-weight: 500;}
.infoBox_number{display: flex; width: 100%; flex-flow: row nowrap; font-size: 26px; font-weight: 700; position: relative;}
.infoBox_footertext{display: flex; width: 100%; flex-wrap: wrap; font-size: 12px; color: #5C6576; font-weight: 600; position: relative;}

.infoBox_outer.act{background-color: #0D6CCA;}
.infoBox_outer.act .infoBox_title, .infoBox_outer.act .infoBox_number, .infoBox_outer.act .infoBox_footertext{color: #fff;}

.infoBox_outer.act .infoBox_footertext{padding-left: 15px;}
.infoBox_outer.act .infoBox_footertext::before{content: ''; width: 0; height: 0;
  border-top: 6px solid transparent; border-left: 10px solid #FDE89F; border-bottom: 6px solid transparent; position: absolute; left: 0; top: 2px;}


/* background: */
.infoBox_number progress::-webkit-progress-bar {background-color: #DCE0E7; width: 100%; height: 2px; border-radius: 4px;}
.infoBox_number progress {background-color: #DCE0E7; height: 2px; border-radius: 4px;}

/* value: */
.infoBox_number progress::-webkit-progress-value {background-color: #09A635 !important; border-radius: 4px;}
.infoBox_number progress::-moz-progress-bar {background-color: #09A635 !important; border-radius: 4px;}
.infoBox_number progress {color: #09A635; border-radius: 4px; position: absolute; left: 0;
  bottom: -11px; width: 100%;}

/* value: */
.infoBox_number.goldtext progress::-webkit-progress-value {background-color: #C79E5B !important; border-radius: 4px;}
.infoBox_number.goldtext progress::-moz-progress-bar {background-color: #C79E5B !important; border-radius: 4px;}
.infoBox_number.goldtext progress {color: #C79E5B; border-radius: 4px; position: absolute; left: 0;
  bottom: -11px; width: 100%;}


/* .triangle_right {
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-left: 100px solid #FDE89F;
  border-bottom: 50px solid transparent;
} */

.dashboard_outer{display: flex; width: 100%; border: 1px solid #EBEEF3; border-radius: 10px; background-color: #fff;}
.dashboard_left{flex-basis: 441px;}
.dashboard_right{flex: 1; border-left: 1px solid #EBEEF3;}
.dashboard_left_title{padding: 30px 30px 0;}
.categoryTabs_outer{display: flex; width: 100%; flex-wrap: wrap; padding: 0 30px;}
.categoryTabs_outer li{margin-right: 8px; margin-bottom: 8px;}
.categoryTab{border: 1px solid #C6CFD7; border-radius: 45px; height: 22px; display: inline-flex; justify-content: center; align-items: center; flex-flow: row nowrap; padding: 0 10px; cursor: pointer;}
.categoryTab .home_icon{width: 12px; height: 12px;}

.db_maintab_outer{display: flex;  width: 100%; flex-direction: column;}
.db_maintab_wpr{display: flex;  width: 100%;}
/* .db_maintab_wpr ul{display: flex; justify-content: space-between; flex-flow: row nowrap; width: 100%; padding: 0 30px;}
.db_maintab_wpr li{display: inline-flex; position: relative; color: #9BA7BC; font-weight: 500; padding-bottom: 4px; cursor: pointer;}
.db_maintab_wpr li.act{color: #182338; font-weight: 600;}
.db_maintab_wpr li.act:after{content: ''; height: 2px; width: 100%; position: absolute; left: 50%; bottom: 0; background-color: #0A69C7; transform: translateX(-50%);} */

.db_maintab_wpr nav{width: 100%;}
.db_maintab_wpr .nav{justify-content: space-between;}
.db_maintab_wpr .nav a{color: #9BA7BC; font-weight: 500; position: relative;}
.db_maintab_wpr .nav-tabs .nav-link.active{color: #182338; font-weight: 600;}
.db_maintab_wpr .nav-tabs .nav-link.active::after{content: ''; height: 2px; width: 50%; position: absolute; left: 50%; bottom: 0; background-color: #0A69C7; transform: translateX(-50%);}
.db_maintab_wpr .nav-tabs .nav-item{margin-bottom: initial;}
.db_maintab_wpr .nav-tabs .nav-link{border: 0; border-radius: 0;}


.targetbar_outer{display: flex; width: 100%; flex-direction: column; padding: 0 30px;}
.targetbar_title{display: flex; justify-content: space-between; width: 100%; flex-flow: row nowrap;}
.targetbar_title_left, .targetbar_title_right{flex: 1;}
.targetbar_title_right{display: inline-flex; flex-flow: row nowrap; justify-content: flex-end; align-items: center;}
.tragetvalue{display: inline-flex; justify-content: center; align-items: center; flex-flow: row nowrap; border: 1px solid #9BA7BC; border-radius: 6px; height: 28px; position: relative; padding: 0 8px;}
/* .tragetvalue input{color: #F47B18; font-weight: 700; font-size: 13px; border: 0; border-radius: 6px; width: 100%; text-align: left; padding-left: 16px; background-color: transparent;} */
/* .tragetvalue::before{content: ''; width: 6px; height: 6px; background: #FB923C; border-radius: 3px; position: absolute; left: 5px; top: 50%; transform: translateY(-50%);} */
.orangeDot{display: inline-flex; width: 6px; height: 6px; background: #FB923C; border-radius: 3px;}
.targetbar{display: flex; width: 100%; flex-direction: column; position: relative;}




.target_range_bar .form-group{margin: 0;}
.target_range_bar .form-control-range {-webkit-appearance: none;width: 100%;height: 6px;
    border-radius: 5px; background: #d3d3d3; outline: none; opacity: 0.7; -webkit-transition: .2s;
    transition: opacity .2s;}
  
  .target_range_bar .form-control-range:hover {opacity: 1;}
  
  .target_range_bar .form-control-range::-webkit-slider-thumb {-webkit-appearance: none;
    appearance: none; width: 18px; height: 18px; border-radius: 50%; background: #fff; cursor: pointer;
    box-shadow: 0px 4px 11px rgba(155, 155, 155, 0.02), 0px 1px 15px rgba(44, 44, 45, 0.03), 0px 1.8px 3.6px rgba(0, 0, 0, 0.1); border: 1px solid #FC8A2D;}
  
  .target_range_bar .form-control-range::-moz-range-thumb {width: 18px;
    height: 18px;border-radius: 50%;background: #fff;cursor: pointer;
    box-shadow: 0px 4px 11px rgba(155, 155, 155, 0.02), 0px 1px 15px rgba(44, 44, 45, 0.03), 0px 1.8px 3.6px rgba(0, 0, 0, 0.1); border: 1px solid #FC8A2D;}

.targetvalue_outer{display: flex; justify-content: space-between; width: 100%; flex-flow: row nowrap;}
.valuecount{display: inline-flex; justify-content: flex-start; align-content: center; flex-flow: row nowrap; position: relative; padding-left: 15px;}
.valuecount_gold{color: #C79E5B;}
.valuecount_gold::before, .valuecount_green::before, .valuecount_blue::before{content: ''; position: absolute; left: 0; top: 50%; transform: translateY(-50%); width: 10px; height: 10px; background-color: #C79E5B; border-radius: 3px;}
.valuecount_green::before{background-color: #08847C;}
.valuecount_blue::before{background-color: #0D6CCA;}
.valuecount_green{color: #08847C;}
.valuecount_blue{color: #0D6CCA;}
.targetvalue_prnt{flex: 1; padding: 0 10px;}
.targetvalue_prnt:first-child{padding-left: 0;}

.devider_line{display: flex; flex-flow: row nowrap; height: 1px; background-color: #EBEEF3; width: 100%;}
.graphvalue{display: flex; justify-content: space-between; width: 100%; flex-flow: row nowrap; padding: 30px 15px 0;}
.graphvalue_left, .graphvalue_right{display: inline-flex; align-items: flex-start; flex: 1; flex-flow: row nowrap;}
.graphvalue_count{display: inline-flex; height: 40px; background-color: #FCF8EB; border-radius: 25px; padding: 0 11px; align-items: center; padding-left: 25px; position: relative;}
.graphvalue_count::before{content: ''; position: absolute; left: 10px; top: 50%; transform: translateY(-50%); width: 8px; height: 8px; background-color: #FB923C; border-radius: 11px; }

.graphtabs nav{width: 100%;}
.graphtabs .nav{justify-content: flex-start;}
.graphtabs .nav a{color: #9BA7BC; font-weight: 500; position: relative;}
.graphtabs .nav-tabs .nav-link.active{color: #182338; font-weight: 600;}
.graphtabs .nav-tabs .nav-link.active::after{content: ''; height: 2px; width: 50%; position: absolute; left: 50%; bottom: 0; background-color: #0A69C7; transform: translateX(-50%);}
.graphtabs .nav-tabs .nav-item{margin-bottom: initial;}
.graphtabs .nav-tabs .nav-link{border: 0; border-radius: 0;}

.graph_container{display: block; width: 100%; position: relative; min-height: 321px; background-color: #f2f2f2;}

.graph_list_outer{display: flex; width: 100%; flex-flow: row nowrap; padding: 30px 15px 25px;}
.graph_detail_list{display: grid; grid-template-columns: repeat(4,1fr); grid-row-gap: 20px; grid-column-gap: 15px; font-size: 12px; color: #566072; font-weight: 500; width: 100%;}
.graph_detail_list li{display: inline-flex; align-items: center; position: relative;white-space: nowrap;}
.dashline{display: inline-flex; width: 10px; height: 4px; background-color: #FB923C;}
.bgcolorbb8{background-color: #BB883A;}
.bgcolor0dc{background-color: #0D6CCA;}
.bgcolor088{background-color: #08847C;}
.bgcolor8c7{background-color: #8C7AFA;}

.rupee_greenbox{display: inline-flex; width: 14px; height: 14px; align-items: center; justify-content: center; background-color: #09A635; box-shadow: 0px 2.8px 7.7px rgba(155, 155, 155, 0.02), 0px 0.7px 10.5px rgba(44, 44, 45, 0.03); border-radius: 4px; background-image: url(../img/svg/rupee_white.svg); background-size: 85%; background-repeat: no-repeat; background-position: center;}

.flag_bluebox{display: inline-flex; width: 14px; height: 14px; align-items: center; justify-content: center; background-color: #0D6CCA; box-shadow: 0px 2.8px 7.7px rgba(155, 155, 155, 0.02), 0px 0.7px 10.5px rgba(44, 44, 45, 0.03); border-radius: 4px; background-image: url(../img/svg/flag_white.svg); background-size: 80%; background-repeat: no-repeat; background-position: center 2px;}

.resetofgoal_box{display: inline-flex; width: 14px; height: 14px; align-items: center; justify-content: center; border-color: #0D6CCA; border-style: solid; border-width: 1.5px;  border-radius: 4px;}
.listcheck_outer{cursor: pointer;}
.listcheck_outer, .listcheck{display: inline-flex; align-items: center; flex-flow: row nowrap;}
.listcheck_outer .listcheck input{display: none;}
.listcheck_outer .listcheck_box{display: inline-flex; width: 12px; height: 12px; align-items: center; justify-content: center; border: 1px solid #C6CFD7; border-radius: 3px;}
.listcheck_outer .listcheck input[type=checkbox]:checked + .listcheck_box{display: inline-flex; background-color: #2490EF;  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.150951); border-radius: 3px; border: 0; background-image: url(../img/svg/tick_white.svg); background-repeat: no-repeat; background-position: center;}



/* dashboard Goals */
.goalsbanner{display: flex; width: 100%; justify-content: space-between; flex-flow: row nowrap; background-color: #F9F7EF; padding: 25px;}
.addgoal_btn button{border-color: #A5B0C2; display: inline-flex; background-color: #fff; border-radius: 6px;}
.goallist_outer{display: flex; flex-direction: column; width: 100%; padding: 0 30px; position: relative; border-bottom: 1px solid #EBEEF3;}
.goallist{display: flex; width: 100%; flex-direction: column;}
.goallist li{display: flex; width: 100%; flex-flow: row nowrap; align-items: center; padding: 20px 0; position: relative; justify-content: space-between;}
.goallist li::after{content: ''; height: 1px; width: calc(100% - 40px); background-color: #EBEEF3; position: absolute; left: 40px; bottom: 0;}
.goallist li:last-child::after{display: none;}
.goallist_icon{display: inline-flex; align-items: center; justify-content: center; width: 20px; height: 20px; border-radius: 6px; border: 1px solid #0D6CCA; margin: 0 10px; padding: 3px;}
.goaledit_icon{display: inline-flex; justify-content: center; align-content: center; cursor: pointer; background: #F2F4F8 url(../img/svg/edit.svg); width: 34px; height: 28px; border-radius: 40px; background-repeat: no-repeat; background-position: center; background-size: 45%;}
.goallist_left{display: inline-flex; align-items: center; flex-flow: row nowrap; flex: 1;}
.rangebox{display: flex; justify-content: space-between; flex-flow: row nowrap; flex: 0 0 130px; background: #F2F4F8; border-radius: 40px; height: 28px; align-items: center; padding: 0 10px;}
.goal_range_slider{display: inline-flex;}
.goal_range_slider .custom-range{background: #DCE1EA; border-radius: 2px; height: 4px; width: 100%;}
.goal_range_slider input[type="range"]::-webkit-slider-runnable-track{-webkit-appearance: none;
  appearance: none; }
.goal_range_slider input[type="range"]::-webkit-slider-thumb {-webkit-appearance: none;
  appearance: none; width: 10px; height: 10px; border-radius: 50%; background: #fff; cursor: pointer;
  box-shadow: 0px 4px 11px rgba(155, 155, 155, 0.02), 0px 1px 15px rgba(44, 44, 45, 0.03), 0px 1.8px 3.6px rgba(0, 0, 0, 0.1); border: 1px solid #EBEEF0;}

.goal_range_slider input[type="range"]::-moz-range-thumb {width: 10px; height: 10px;border-radius: 50%;background: #fff;cursor: pointer; box-shadow: 0px 4px 11px rgba(155, 155, 155, 0.02), 0px 1px 15px rgba(44, 44, 45, 0.03), 0px 1.8px 3.6px rgba(0, 0, 0, 0.1); border: 1px solid #EBEEF0;}

/* dashboard Goals */

/* dashborad cashflow */
.db_cashflow_outer{width: 100%;}
.db_cashflow_outer .nav{justify-content: flex-start;}
.db_cashflow_outer .nav-item{margin-right: 10px;}
.db_cashflow_outer .nav-pills .nav-link{border-radius: 45px; padding: 5px 8px; background-color: #EBEEF3; color: #353F51;}
.db_cashflow_outer .nav-pills .nav-link.active, .db_cashflow_outer .nav-pills .show>.nav-link{background-color:#0A69C7; color: #fff;}
.cashflow_list{display: flex; width: 100%; flex-direction: column; border-bottom: 1px solid #EBEEF3;}
.cashflow_list_title{display: flex; width: 100%; position: relative; padding-left: 26px;}
.cashflow_list_title::before{content: ''; background: #CEDA9D; border-radius: 5px;width: 20px;
  height: 11px; position: absolute; left: 0; top: 6px;}
.cashflow_list_title.orangedotclr::before{content: ''; background: #FB923C;}
.cashflow_inflow_wpr{display: flex; width: 100%; flex-direction: column;}
.cashflow_inflow_title{display: flex; justify-content: space-between; align-items: center; flex-flow: row nowrap;}
.addinflow_btn{background-color: #fff; border-color: #A5B0C2; padding: 0 8px; display: inline-flex; border-radius: 6px; height: 24px; align-items: center; justify-content: center; flex-flow: row nowrap;}
.inflow_progressbar{display: flex; flex-direction: column; width: 100%;}
.inflow_progressbar progress{width: 100%; position: relative;}

/* background: */
.inflow_progressbar progress::-webkit-progress-bar {background-color: #C6DA9D; width: 100%; height: 12px; border-radius: 6px;}
.inflow_progressbar progress {background-color: #C6DA9D; height: 12px; border-radius: 6px;}

/* value: */
.inflow_progressbar progress::-webkit-progress-value {background-color: #DBE4C6 !important; border-radius: 4px;}
.inflow_progressbar progress::-moz-progress-bar {background-color: #DBE4C6 !important; border-radius: 6px;}
.inflow_progressbar progress {color: #DBE4C6; border-radius: 6px; width: 100%;}

/* background: */
.cashflow_outflow_wpr .inflow_progressbar progress::-webkit-progress-bar {background-color: #FF8B36; width: 100%; height: 12px; border-radius: 6px;}
.cashflow_outflow_wpr .inflow_progressbar progress {background-color: #FF8B36; height: 12px; border-radius: 6px;}

/* value: */
.cashflow_outflow_wpr .inflow_progressbar progress::-webkit-progress-value {background-color: #F8DE82 !important; border-radius: 4px;}
.cashflow_outflow_wpr .inflow_progressbar progress::-moz-progress-bar {background-color: #F8DE82 !important; border-radius: 6px;}
.cashflow_outflow_wpr .inflow_progressbar progress {color: #F8DE82; border-radius: 6px; width: 100%;}

.earnincome_outer{display: flex; flex-direction: column; width: 100%; border-bottom: 1px solid #EBEEF3; padding-bottom: 20px;}
.earnincome_outer:last-child{border: 0;}
.earnincome_title{display: flex; width: 100%; flex-flow: row nowrap; position: relative; padding-left: 25px;}
.earnincome_title::before{content: ''; background: #DBE4C6; border-radius: 4px;width: 15px; height: 10px; position: absolute; left: 0; top: 3px;}
.incomeinfo{display: grid; grid-template-columns: repeat(3,1fr); grid-gap: 20px;}


.cashflow_outflow_wpr .incomeinfo{grid-template-columns: repeat(4,1fr);}
.orangclr_dot.earnincome_title::before{content: ''; background: #F8DE82;}
.darkorangclr_dot.earnincome_title::before{content: ''; background: #FF8B36;}

.loandown{display: inline-flex; align-items: center; justify-content: center; flex-flow: row nowrap; height: 14px; padding: 0 3px; background: #FED773; border-radius: 5px;}

/* switch */
.switch_btn{display: inline-flex; align-items: center;}
.switch{cursor:pointer;display:inline-block;height:12px;position:relative;width:20px; margin-bottom: 0;}
.switch input{height:0;opacity:0;width:0}
.switch .slider{background-color:#DAE1E9;border:1px solid #e4e4e4;border-radius:34px;bottom:0;cursor:pointer;left:0;position:absolute;right:0;top:0;transition:.3s;-webkit-transition:.3s}
.switch .slider:before{background-color:#fff;border-radius:50%;bottom:1px;box-shadow:0 0 5px 0 rgba(0,0,0,0.2);content:"";height:8px;left:0;position:absolute;transition:.3s;-webkit-transition:.3s;width:8px}
.switch input:checked + .slider{background-color:#0076d7;border:1px solid #0076d7}
.switch input:checked + .slider:before{-ms-transform:translateX(9px);transform:translateX(9px);-webkit-transform:translateX(9px)}
/* switch */

.addinflow_btn .edit_icon{width: 12px; height: 12px;}

/* dashborad cashflow */

/* dashborad investments */
.db_investments_outer{display: flex; width: 100%; flex-direction: column;}
.db_investments_title{display: flex; width: 100%; justify-content: space-between; background-color: #F1EEFB; padding: 15px 30px;}


/* background: */
.currentallocation_bar progress::-webkit-progress-bar {background-color: #68CA84; width: 100%; height: 12px; border-radius: 6px;}
.currentallocation_bar progress {background-color: #68CA84; height: 12px; border-radius: 6px;}

/* value: */
.currentallocation_bar progress::-webkit-progress-value {background-color: #C3E7CD !important; border-radius: 4px;}
.currentallocation_bar progress::-moz-progress-bar {background-color: #C3E7CD !important; border-radius: 6px;}
.currentallocation_bar progress {color: #C3E7CD; border-radius: 6px; width: 100%;}

.lightgreen_dot.earnincome_title::before{content: ''; background: #C3E7CD;}
.mediumgreen_dot.earnincome_title::before{content: ''; background: #89DCA0;}
.darkgreen_dot.earnincome_title::before{content: ''; background:#68CA84;}

/* dashborad investments */

/* Dashboard Family */
.familyinfo{display: flex; justify-content: space-between; flex-flow: row nowrap; width: 100%; border-bottom: 1px solid #EBEEF3; padding-bottom: 10px;}
.familyinfo_left, .familyinfo_right{flex: 1;}
.familyinfo_tab{display: inline-flex; align-items: center; justify-content: center; border: 1px solid #C6CFD7; border-radius: 45px; height: 22px; padding: 0 8px; margin-right: 10px; margin-bottom: 10px;}
.crown_icon{background-image: url(../img/svg/crown.svg); width: 14px; height: 10px; background-repeat: no-repeat; background-position: center; background-size: 100%; display: inline-block ;}
.crownblue_icon{background-image: url(../img/svg/crownblue.svg); width: 14px; height: 10px; background-repeat: no-repeat; background-position: center; background-size: 100%; display: inline-block ;}
.clock_icon{background-image: url(../img/svg/clock.svg); width: 12px; height: 12px; background-repeat: no-repeat; background-position: center; background-size: 100%; display: inline-block ;}
.arrow_down{background-image: url(../img/svg/arrow_down.svg); width: 12px; height: 12px; background-repeat: no-repeat; background-position: center; background-size: 100%; display: inline-block ;}
.goal_gray{background-image: url(../img/svg/goal_gray.svg); width: 13px; height: 13px; background-repeat: no-repeat; background-position: center; background-size: 100%; display: inline-block ;}
.persondtl_wpr{display: flex; flex-direction: column; width: 100%;}
.persondtl_title{display: flex; justify-content: space-between; flex-flow: row nowrap; width: 100%;}
.person_cover_wpr{display: flex; flex-direction: column; width: 100%; padding: 15px 20px; border: 1px solid #EDEFF2; border-radius: 10px;}
.person_cover_outer{display: flex; flex-flow: row nowrap; width: 100%; justify-content: space-between;}

.person_cover_left, .person_cover_right{flex: 1;}
.coverprogress_bar{display: flex; width: 100%; flex-flow: row nowrap;}
.coverprogress_bar progress{width: 100%; background: #DCE0E7; border-radius: 4px;}
/* background: */
.coverprogress_bar progress::-webkit-progress-bar {background-color: #DCE0E7; width: 100%; height: 2px; border-radius: 4px;}
.coverprogress_bar progress {background-color: #DCE0E7; height: 2px; border-radius: 4px;}

/* value: */
.coverprogress_bar progress::-webkit-progress-value {background-color: #0D6CCA !important; border-radius: 4px;}
.coverprogress_bar progress::-moz-progress-bar {background-color: #0D6CCA !important; border-radius: 4px;}
.coverprogress_bar progress {color: #0D6CCA; border-radius: 4px; width: 100%;}
.color5c6{color: #5C6576;}
.person_cover_right{display: inline-flex; justify-content: space-between; flex-flow: row nowrap; width: 100%;}
.suggestbox{ border-radius: 6px; border-radius: 6px; padding: 10px 15px; background: linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), linear-gradient(0deg, #E5E8EF, #E5E8EF), linear-gradient(0deg, #FBFBFD, #FBFBFD), #FFFFFF; flex: 1;} 
.color495{color: #495262;}
.updown_arrow{display: inline-flex; position: relative; width: 2px; border-right: 2px dotted #D1D6E2;}
.updown_arrow::before{content: ''; width: 0; height: 0; border-left: 6px solid transparent;
  border-right: 6px solid transparent; border-bottom: 6px solid #0A69C7; font-size: 0; line-height: 0;
  float: left; position: absolute; left: 7px; cursor: pointer;}
.updown_arrow::after{content: ''; width: 0; height: 0; border-left: 6px solid transparent;
    border-right: 6px solid transparent; border-top: 6px solid #0A69C7; font-size: 0; line-height: 0;
    float: left; position: absolute; left: 7px; cursor: pointer; bottom: 0;}
.person_cover_tabs{display: flex; width: 100%; flex-wrap: wrap;}
.person_cover_tabs li{display: inline-flex; margin-right: 10px;}
.person_cover_tabs label{flex: 1; cursor: pointer;}
.person_cover_tabs label input{display: none;}
.person_cover_tabs .cover_checkbox{display: inline-flex; height: 22px; align-items: center; justify-content: center; flex-flow: row nowrap; padding: 0 8px;}
.person_cover_tabs label input[type="radio"]:checked + .cover_checkbox{display: inline-flex; height: 22px; align-items: center; justify-content: center; flex-flow: row nowrap; background: linear-gradient(0deg, #EBEEF3, #EBEEF3), #FFFFFF; border-radius: 45px; padding: 0 8px;}

.fullprotected .color162{color: #22AF49;}
.fullprotected b{color: #22AF49; font-weight: 700;}
/* value: */
.fullprotected .coverprogress_bar progress::-webkit-progress-value {background-color: #22AF49 !important; border-radius: 4px;}
.fullprotected .coverprogress_bar progress::-moz-progress-bar {background-color: #22AF49 !important; border-radius: 4px;}
.fullprotected .coverprogress_bar progress {color: #22AF49; border-radius: 4px; width: 100%;}



/* Dashboard Family */

/* popup */
.rightSide_menu{position: fixed; right: 0; top: 0; width: 400px; background-color: #fff; height: 100%; border: 1px solid #EDEFF2; box-shadow: 0px 5px 22px rgba(154, 154, 156, 0.02), 0px 4px 11px rgba(155, 155, 155, 0.02), 0px 1px 15px rgba(44, 44, 45, 0.03); z-index: 2; overflow-y: auto; padding-bottom: 66px;}
.rightSide_menu::-webkit-scrollbar{display: none;}
.rightSide_menu_header{display: flex; justify-content: space-between; align-items: center; flex-flow: row nowrap; width: 100%; border-bottom: 1px solid #EDEFF2; height: 48px; position: relative; padding: 0 20px;}
.rightSide_menu_header .btn{font-size: 12px; padding: 2px 12px;}
.addbtn{margin-left: 5px; background-color: #f2f2f2; border-radius: 10px; padding: 4px 10px; cursor: pointer;}
select.selectYear{appearance: auto;}
.topBanner{display: flex; width: 100%; flex-direction: column; min-height: 140px; background: rgba(219, 228, 198, 0.2); border-bottom: 1px solid #F7F9FB; box-shadow: inset 1px 0px 0px #ECEEF0; background-image: url(../img/home_form_img.png); background-repeat: no-repeat; background-position: right bottom; background-size: 40%;}
.color485{color: #485670;}

.collapse_outer{display: flex; flex-direction: column; width: 100%; border: 1px solid #E7F2FE;
  border-radius: 10px;background-color: #F7F9FB; padding: 16px;}
.collapse_title{display: flex; align-items: center; width: 100%; justify-content: space-between; flex-flow: row nowrap;}
.expandtab_outer{display: flex; flex-wrap: wrap; width: 100%;}
.expandtab{display: inline-flex; background: #FFFFFF; border-radius: 45px; border: 1px solid #C6CFD7; padding: 2px 8px; margin-right: 8px; margin-bottom: 8px; cursor: pointer;}
.expandtab b{padding-left: 4px;}
.color353{color: #353F51;}
.expandBtn{border-radius: 60px;}
.collapse_outer .card-body{padding: 0; border: 0; background-color: transparent;}
.educationtabs{display: inline-flex; align-items: center; justify-content: center; border: 1px solid #EBEEF3;
border-radius: 45px; cursor: pointer; border: 1px solid #EBEEF3; border-radius: 45px; background-color: #F7F9FB; padding: 4px 10px;}
.rightSide_menu .custome_form .form-row .col-md-6 .input-group .custom-select{max-width: 60%;}


.rangesliderbox{display: flex; width: 100%; flex-direction: column;}
.rangesliderbox_title{display: flex; justify-content: space-between; width: 100%; flex-flow: row nowrap;}
.rangebox_bar .form-group{margin: 0;}
.rangebox_bar .form-control-range {-webkit-appearance: none;width: 100%;height: 6px;
    border-radius: 5px; background: #d3d3d3; outline: none; opacity: 0.7; -webkit-transition: .2s;
    transition: opacity .2s;}
.rangebox_bar .form-control-range:hover {opacity: 1;}
.rangebox_bar .form-control-range::-webkit-slider-thumb {-webkit-appearance: none;
    appearance: none; width: 18px; height: 18px; border-radius: 50%; background: #fff; cursor: pointer;
    box-shadow: 0px 4px 11px rgba(155, 155, 155, 0.02), 0px 1px 15px rgba(44, 44, 45, 0.03), 0px 1.8px 3.6px rgba(0, 0, 0, 0.1); border: 1px solid #0D6CCA;}
.rangebox_bar .form-control-range::-moz-range-thumb {width: 18px; height: 18px;border-radius: 50%;background: #fff;cursor: pointer;
    box-shadow: 0px 4px 11px rgba(155, 155, 155, 0.02), 0px 1px 15px rgba(44, 44, 45, 0.03), 0px 1.8px 3.6px rgba(0, 0, 0, 0.1); border: 1px solid #0D6CCA;}
.color8c9{color: #8C9CB9;}

/* popup */



/* new family card */
.familycard{display: block;}
.familycard.graybg{background-color: rgba(231, 231, 231, 0.2); border-top:1px solid #EBEEF3; border-bottom:1px solid #EBEEF3;} 
.familycard_title{display: flex; justify-content: space-between; align-items: center; width: 100%;}
.bluerightarrow{background-image: url(../img/bluerightarrow.png); width: 5px; height: 8px; display: inline-block; background-repeat: no-repeat; background-size: 100%;}
.title_viewmore{display: inline-flex; align-items: center;}
.colora5b{color: #A5B0C2;}
.familycard_content{display: flex; width: 100%;}
.familycard_content_left, .familycard_content_mdl, .familycard_content_right{flex: 1; flex-direction: column;}
.familycard_content_left{display: inline-flex; align-items: flex-start;}
.familycard_content_mdl{display: inline-flex; align-items: center;}
.familycard_content_right{display: inline-flex; align-items: flex-end;}
.familycardtabs .nav-pills .nav-link{border-radius: 45px; padding: 5px 8px; background-color: #EBEEF3; color: #353F51;}
.familycardtabs .nav-pills .nav-link.active, .db_cashflow_outer .nav-pills .show>.nav-link{background-color:#0A69C7; color: #fff;}
.membercharthdr{display: flex; width: 100%; align-items: center; border-bottom: 1px solid #EBEEF3; padding-bottom: 10px;}
.membercharthdr .membercharthdr_inner{display: inline-flex; flex: 1; align-items: center; justify-content: center;}
.membercharthdr .membercharthdr_inner:first-child{flex: 0 0 190px; min-width: 190px; justify-content: flex-start;}
.color949{color:#949CAA;}
.memberchart_title{letter-spacing: 0.2em;}
.bluebadge{background-image: url(../img/bluebadge.png); width: 10px; height: 7px; display: inline-block; background-repeat: no-repeat; background-size: 100%;}
.goldbadge{background-image: url(../img/goldbadge.png); width: 10px; height: 7px; display: inline-block; background-repeat: no-repeat; background-size: 100%;}
.familyicon{background-image: url(../img/familyicon.png); width: 14px; height: 9px; display: inline-block; background-repeat: no-repeat; background-size: 100%;}
.insuranceicon{background-image: url(../img/insuranceicon.png); width: 10px; height: 11px; display: inline-block; background-repeat: no-repeat; background-size: 100%;}
.fundicon{background-image: url(../img/fundicon.png); width: 14px; height: 14px; display: inline-block; background-repeat: no-repeat; background-size: 100%;}
.familycard_title_left{display: inline-flex; align-items: center;}
.coveragetitle{display: flex; align-items: center; width: 100%;}
.coveragetitle_icon{flex: 0 0 40px; min-width: 40px; height: 40px; display: inline-flex; align-items: center; justify-content: center; background-color: #DDFBD6; border-radius: 50%;}
.coverageicon2{background-image: url(../img/coverageicon2.png); width: 14px; height: 12px; display: inline-block; background-repeat: no-repeat; background-size: 100%;}
.coverageicon1{background-image: url(../img/coverageicon1.png); width: 12px; height: 13px; display: inline-block; background-repeat: no-repeat; background-size: 100%;}
.coveragetitle_icon_blue{background-color: #EAF8F9;}

.coveragechart_card{display: block;}
.coveragechart_card_hdr, .coveragechart_card_row{display: flex; width: 100%;}
.coveragechart_card_hdr_inner, .coveragechart_card_row_inner{flex: 1; text-align: center; align-items: center; justify-content: center; display: inline-flex;}
.coveragechart_card_hdr_inner:first-child, .coveragechart_card_row_inner:first-child{display: inline-flex; justify-content: flex-start; }
.coveragechart_card_hdr_inner:last-child, .coveragechart_card_row_inner:last-child{display: inline-flex; justify-content: flex-end;}
.color949{color: #949CAA;}
.coveragechart_card_row{background-color: rgba(235, 238, 243, 0.3); padding: 16px 30px; width: calc(100% + 60px); position: relative; left: -30px; border-top: 1px solid #EBEEF3; border-bottom: 1px solid #EBEEF3;}
.colorfb9{color: #FB923C;}

.greentick {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  width: 22px;
  height: 22px;
  background: #2DB266 url(../img/svg/tick_white.svg);
  background-repeat: no-repeat;
  background-size: 55%;
  background-position: center;
  border-radius: 50%;
  visibility: visible;
}

.coveragechart_card_row_inner .greentick{visibility: visible; width: 16px; height: 16px;}
.emergencyfund_title_flex{display: flex; justify-content: space-between; align-items: flex-start;}
.emergencyfund_title_flex .coveragetitle{display: inline-flex; width: auto;}

.imgbanner{background-image: url(../img/img_banner1.png); width: 226px; height: 228px; display: inline-block; background-size: 100%; background-repeat: no-repeat; position: absolute; right: 5px; bottom: 5px;}

.progress-bar progress{visibility:hidden;height:0;width:0;}
.progress-bar {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: 
    radial-gradient(closest-side, white 79%, transparent 80% 100%),
    conic-gradient(#FB923C 75%, #EBEEF3 0);    
}

.bigrupeecoinicon{background-image: url(../img/bigrupeecoinicon.png); width: 40px; height: 45px; display: inline-block; background-size: 100%; background-repeat: no-repeat;}
.goalicon{background-image: url(../img/goalicon.png); width: 54px; height: 41px; display: inline-block; background-size: 100%; background-repeat: no-repeat;}


/* new family card */



@media only screen and (max-width: 767px) {
  .infoBox_wpr{grid-template-columns: repeat(2,1fr); padding-left: 20px; padding-right: 20px; grid-gap: 15px;}
  .infoBox_outer:first-child{grid-column: 1 / -1;}
  /* .infoBox_outer{width: 50%;} */
  .dashboard_outer{border: 0; padding: 0 15px;}
  .dashboard_left{border: 1px solid #EDEFF2; box-shadow: 0px 5px 22px rgba(154, 154, 156, 0.02), 0px 4px 11px rgba(155, 155, 155, 0.02), 0px 1px 15px rgba(44, 44, 45, 0.03); border-radius: 10px;}
  .dashboard_right{display: none;}
  .mob_pl20_pr20{padding-left: 20px; padding-right: 20px;}
  .mob_pl15_pr15{padding-left: 15px; padding-right: 15px;}
  .dashboard_left_title, .categoryTabs_outer{padding-left: 15px; padding-right: 15px; padding-top: 20px; margin-top: 0;}
  .db_maintab_wpr nav{font-size: 12px;}
  .db_maintab_wpr .nav-tabs .nav-link{padding: 10px;}
  .db_maintab_outer{margin-top: 20px;}
  .targetbar_outer, .goallist_outer, .goalsbanner, .db_cashflow_outer{padding-left: 15px; padding-right: 15px;}
  .mainwrapper > .mt30{margin-top: 20px;}
  .rangebox{flex-basis: auto; background-color: transparent; position: relative; cursor: pointer;}

  .arrow-right,
  .arrow-left,
  .long-arrow-right,
  .long-arrow-left{
    display: block;
    width: 8px;
    height: 8px;
    border-top: 2px solid rgba(0, 0, 0, 0.7);
    border-left: 2px solid rgba(0, 0, 0, 0.7);
    position: absolute;
    right: 0;
    top: 9px;
  }
  .arrow-right,
  .long-arrow-right{
  transform: rotate(135deg);
  }

.db_cashflow_outer .nav-item{margin-bottom: 10px;}

.rightSide_menu_outer{position: fixed; left: 0; top: 0; right: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.6); z-index: 1;}
.rightSide_menu{width: 100%; top: initial; bottom: 0; height: auto; overflow: auto; padding-bottom: 66px; max-height: 85%; border-radius: 15px 15px 0 0; overflow-y: auto;}
.saveBtn{position: fixed; width: 100%;}

}

@media only screen and (min-width: 768px) and (max-width: 959px){


}


/* iPad in portrait & landscape */

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px)  { 
 .innercontainer{width: 100%;}
 .row {margin-left: 0; margin-right: 0;}
 .db_cashflow_outer .nav-item{margin-bottom: 10px;}
 .mob_pl20_pr20, .infoBox_wpr{padding-left: 20px; padding-right: 20px;}
 .infoBox_wpr{grid-template-columns: repeat(3,1fr);}
 .dashboard_left_title, .categoryTabs_outer{padding-left: 20px; padding-right: 20px;}

}

/* iPad in landscape */

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : landscape) { 

}


/* iPad in portrait */

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait) { 
    .dashboard_right{display: none;}
    .dashboard_left{flex-basis: 100%;}
 }